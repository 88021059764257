<template>
  <footer id="footer" class="the-footer">
    <div class="the-footer__container">
      <a href="/" class="the-footer__logo">
        <img src="/images/logo_white.png" alt="LOGO">
        <span>{{ $t('footer.logo.motto')}}</span>
      </a>

      <div class="the-footer__menu">
        <div
          v-for="(menuGroup, groupKey) in menuItems"
          :key="groupKey"
          class="the-footer__menu-group"
        >
          <div
            class="the-footer__menu-group-header"
          >{{ $t(`menu.footer.${groupKey}`) }}</div>

          <template v-for="(menuItem, key) in menuGroup.submenu">
            <router-link
              v-if="menuItem.to"
              :key="`menu-${key}`"
              :to="menuItem.to"
              class="the-footer__menu-item"
              exact-active-class="the-footer__menu-item--active"
            >{{ $t(`menu.footer.${key}`) }}</router-link>
          </template>
        </div>
      </div>

      <div class="the-footer__subscribe">
        <div class="the-footer__social">
          <a href="https://www.linkedin.com/company/quietvox">
            <img src="/images/icon_linkedin.png" alt="LINKEDIN">
          </a>
          <a href="https://www.instagram.com/quietvox_com/">
            <img src="/images/icon_instagram.png" alt="INSTAGRAMM">
          </a>
        </div>
      </div>

    </div>
    <the-cookie-manager />
  </footer>
</template>

<script>
import TheCookieManager from '@/components/the-cookie-manager';

export default {
  components: {
    TheCookieManager,
  },
  data() {
    return {
      menuItems: {
        products: {
          submenu: {
            accessories: { to: { name: 'accessories' } },
            qv9_mini: { to: { name: 'devices', hash: '#transmitter-QV9-mini' } },
            qv9: { to: { name: 'devices', hash: '#transmitter-QV9' } },
            qv8: { to: { name: 'devices', hash: '#receiver-QV8' } },
          },
        },
        sustainable: {
          submenu: {
            solutions: { to: { name: 'suistainable' } },
          },
        },
        faq: {
          submenu: {
            payments: { to: { name: 'faq', params: { thema: 'payments' } } },
            deliveries: { to: { name: 'faq', params: { thema: 'deliveries' } } },
            devices: { to: { name: 'faq', params: { thema: 'devices' } } },
          },
        },
        contacts: {
          submenu: {
            our_contacts: { to: { name: 'contacts' } },
            contact_us: { to: { name: 'contacts', hash: '#contact-form' } },
            imprint: { to: { name: 'imprint' } },
          },
        },
      },
    };
  },
};
</script>

<style lang="scss">
.the-footer {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #313030;

  color: $white;

  &__container {
    @include container;

    display: flex;
    align-items: flex-start;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    font-size: 15px;

    img {
      margin-bottom: 12px;
    }

    &,
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__menu {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-around;

    &-group {
      padding: 0 16px;
      display: flex;
      flex-direction: column;

      &-header {
        font-size: 16px;
        line-height: 1.38;
        color: $white;
        font-weight: 700;
        padding-bottom: 8px;
      }
    }

    &-item {
      font-size: 16px;
      line-height: 1.38;
      color: $white;
      font-weight: 400;
      padding-bottom: 8px;

      text-decoration: none;

      &:hover {
        color: $white;
        text-decoration: underline;
      }
    }
  }

  &__subscribe {
    display: flex;
    flex-direction: column;
  }

  &__social {
    display: flex;

    a {
      margin-right: 26px;
    }
  }

  @include media-bp(tab) {
    &__logo {
      max-width: 150px;
      font-size: 8px;

      img {
        margin-bottom: 8px;
      }
    }

    &__menu {
      &-group {
        padding: 0 8px;

        &-header {
          font-size: 12px;
        }
      }
      &-item {
        font-size: 12px;
      }
    }

  }

  @include media-bp(mob) {
    padding-top: 16px;
    padding-bottom: 16px;

    &__container {
      flex-direction: column-reverse;
      align-items: center;
    }

    &__logo {
      max-width: 185px;
      font-size: 8px;
    }

    &__menu {
      display: none;
    }

    &__social {
      margin-bottom: 16px;
    }
  }
}
</style>
