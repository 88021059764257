<template>
  <!-- hide languages selection until we have multilanguages -->
  <div
    v-if="false"
    class="app-languages"
    :class="{
      'app-languages--opened': isOpened,
      'app-languages--short': short,
      'app-languages--list': list,
    }"
    v-click-outside="onClickOutside"
  >
    <div
      class="app-languages__button"
      @click="openMenu"
    >
      <div class="app-languages__item app-languages__item--button">
        <img
          class="app-languages__item-image"
          :src="$t(`language.${actualLanguageCode}.icon`)"
          :alt="$t(`language.${actualLanguageCode}.name`).toUpperCase()"
        >
        <div class="app-languages__item-text">
          {{  short ? actualLanguageCode : $t(`language.${actualLanguageCode}.name`) }}
        </div>
      </div>
    </div>

    <div class="app-languages__menu">
      <div
        v-for="(langCode, index) in languages"
        :key="index"
        class="app-languages__item"
        :class="{ 'app-languages__item--active': langCode === actualLanguageCode}"
        @click="setLanguage(langCode)"
      >
        <img
          v-if="icons"
          class="app-languages__item-image"
          :src="$t(`language.${langCode}.icon`)"
          :alt="$t(`language.${langCode}.name`).toUpperCase()"
        >
        <div class="app-languages__item-text">
          {{ short ? langCode : $t(`language.${langCode}.name`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    short: Boolean,
    list: Boolean,
    icons: { type: Boolean, default: true },
  },
  data() {
    return {
      languageCode: '',
      isOpened: false,
    };
  },
  computed: {
    languages() {
      return ['en', 'fr', 'de', 'it', 'pt', 'es'];
    },
    actualLanguageCode: {
      get() {
        return this.languageCode || 'en';
      },
      set(value) {
        this.languageCode = value;
      },
    },
  },
  methods: {
    openMenu() {
      this.isOpened = !this.isOpened;
    },
    setLanguage(code) {
      this.actualLanguageCode = code;
      this.isOpened = false;

      this.$emit('language-changed', code);
    },
    onClickOutside() {
      if (this.isOpened) this.isOpened = false;
    },
  },
};
</script>

<style lang="scss">
.app-languages {
  $root: &;
  position: relative;
  color: $black;

  &__button {
    display: flex;
    align-items: center;
    height: 32px;
    min-width: 150px;
    background: $white;
    border: 1px solid #524B4B;
    border-radius: 8px;

    cursor: pointer;
  }

  &__button &__item {
    text-transform: uppercase;

    &::after {
      content: '';
      display: block;
      border: 4px solid $black;
      border-top-color: transparent;
      border-right-color: transparent;
      transform: translateY(-30%) rotate(-45deg);

      #{$root}--opened & {
        transform: translateY(30%) rotate(135deg);
      }
    }
  }

  &__menu {
    position: absolute;
    z-index: 5;
    display: none;
    #{$root}--opened & {
      display: flex;
    }

    flex-direction: column;

    min-width: 150px;
    background: $white;
    margin-top: 4px;
    border: 1px solid #524B4B;
    border-radius: 8px;
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 16px;
    user-select: none;

    font-size: 18px;
    line-height: 24.87px;
    text-transform: capitalize;

    cursor: pointer;

    &-image {
      object-fit: contain;
      object-position: center;
      width: 20px;
      max-height: 100%;
      margin-right: 8px;
    }

    &-text {
      flex: 1 0 auto;
      padding-right: 8px;
    }

    &--active {
      font-weight: bold;
      background-color: rgba($red, 0.2);
      pointer-events: none;
    }
  }

  &--short * {
    text-transform: uppercase;
  }

  &--short & {
    &__button,
    &__menu {
      min-width: auto;
    }
  }

  &--list & {
    &__button {
      display: none;
    }
    &__menu {
      position: static;
      display: flex;
      border: none;
    }
    &__item {
      padding: 0 0 6px;
      margin-bottom: 6px;

      &--active {
        background: none;
      }

      &-text {
        padding: 0;
        text-align: center;
      }
    }
  }
}
</style>
