<template>
  <div
    class="app-menu"
    :class="{
      'app-menu--opened': isOpened,
    }"
    v-click-outside="closeMenu"
  >
    <div class="app-menu__hamburger" @click="toggleMenu">
      <img src="./img/burger_btn.png" alt="">
    </div>

    <div class="app-menu__container">
      <app-languages class="app-menu__languages" list short :icons="false" />

      <div class="app-menu__menu" v-click-outside="closeSubmenu">
        <template v-for="(menuItem, key) in menuItems">
          <router-link
            v-if="menuItem.to"
            :key="`menu-${key}`"
            :to="menuItem.to"
            class="app-menu__item"
            exact-active-class="app-menu__item--active"
          >{{ $t(`menu.main.${key}`) }}</router-link>

          <div
            v-else-if="hasSubmenu(key)"
            :key="`menu-main-${key}`"
            class="app-menu__item app-menu__item--submenu"
            :class="{ 'app-menu__item--opened': menuItem.isOpened }"
            active-class="app-menu__item--active"
            @click="toggleSubmenu(key)"
          >
            {{ $t(`menu.main.${key}`) }}

            <div class="app-menu__submenu">
              <template v-for="(submenuItem, subkey) in menuItem.submenu">
                <router-link
                  v-if="submenuItem.to"
                  :key="`submenu-${subkey}`"
                  :to="submenuItem.to"
                  class="app-menu__submenu-item"
                  exact-active-class="app-menu__submenu-item--active"
                >{{ $t(`menu.main.${subkey}`) }}</router-link>
              </template>
            </div>
          </div>

          <a
            v-else-if="menuItem.url"
            :key="`menu-external-${key}`"
            :href="menuItem.url"
            class="app-menu__item"
          >
            {{ $t(`menu.main.${key}`) }}
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import AppLanguages from '@/components/app-languages';

export default {
  components: {
    AppLanguages,
  },
  data() {
    return {
      menuItems: {
        home: { to: { name: 'home' } },
        products: {
          submenu: {
            devices: { to: { name: 'devices' } },
            accessories: { to: { name: 'accessories' } },
            rental: { to: { name: 'rental' } },
            // webshop: { to: { name: 'webshop' } },
          },
          isOpened: false,
        },
        store: { url: 'https://qvlite.com/store/' },
        sustainable: { to: { name: 'suistainable' } },
        contacts: { to: { name: 'contacts' } },
        faq: { to: { name: 'faq' } },
      },
      isOpened: false,
    };
  },
  methods: {
    hasSubmenu(key) {
      const submenu = this.menuItems?.[key]?.submenu;
      return submenu && Object.values(submenu).some(({ to }) => to);
    },
    toggleMenu() {
      this.isOpened = !this.isOpened;
    },
    closeMenu() {
      this.isOpened = false;
    },
    toggleSubmenu(key) {
      if (this.menuItems[key].isOpened) {
        this.closeSubmenu();
      } else {
        this.closeSubmenu();
        this.menuItems[key].isOpened = true;
      }
    },
    closeSubmenu() {
      Object.keys(this.menuItems).forEach((key) => {
        if (this.hasSubmenu(key)) {
          this.menuItems[key].isOpened = false;
        }
      });
    },
  },
  watch: {
    $route: 'closeMenu',
  },
};
</script>

<style lang="scss">
.app-menu {
  $root: &;

  display: flex;
  justify-content: flex-end;

  position: relative;

  &__hamburger {
    display: none;
    cursor: pointer;
  }

  &__languages {
    display: none;
  }

  &__menu {
    display: flex;
    align-items: center;
  }

  &__item {
    padding: 8px 20px;

    &:last-child {
      padding-right: 0;
    }

    font-size: 18px;
    line-height: 24.87px;
    font-weight: 400;

    color: $black;
    text-decoration: none;

    cursor: pointer;
    user-select: none;

    &:hover {
      color: $black;
      text-decoration: none;
    }

    &--active {
      color: $red;
      cursor: default;

      &:hover {
        color: $red;
      }
    }

    &--submenu {
      cursor: pointer;
    }
  }

  &__submenu {
    position: absolute;
    margin-top: 20px;

    display: none;

    #{$root}__item--opened & {
      display: flex;
    }

    border: 1px solid gray;

    flex-direction: column;
    background-color: $white;
    border-radius: 8px;

    &-item {
      z-index: 1;

      padding: 6px 12px;
      font-size: 18px;
      line-height: 24.87px;
      font-weight: 400;

      color: $black;
      text-decoration: none;

      cursor: pointer;
      user-select: none;

      &--active {
        color: $red;
        cursor: default;

        &:hover {
          color: $red;
        }
      }

      &:hover {
        color: $red;
        text-decoration: none;
      }

      & + & {
        border-top: 1px solid #ddd;
      }
    }
  }

  @include media-bp(tab) {
    &__hamburger {
      display: flex;
      #{$root}--opened & {
        &::before {
          content: "";
          display: block;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.3);
        }

        cursor: default;
      }
    }

    &__container {
      display: none;
      #{$root}--opened & {
        display: flex;
      }

      position: absolute;
      top: 100%;
      right: 0;

      padding: 22px 18px 52px;

      min-width: 250px;

      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px 12px 12px 12px;
    }

    &__languages {
      display: initial;
      padding-right: 16px;
    }

    &__menu {
      flex-direction: column;
      align-items: stretch;
      padding-left: 16px;

      border-left-width: 3px;
      border-style: solid;
      border-image:
        linear-gradient(
          to top,
          rgba(250, 125, 10, 0.245) 1.81%,
          rgba(255, 0, 29, 0.588) 97.31%
        ) 1 100%;
    }

    &__item {
      font-size: 22px;
      line-height: 138.19%;

      padding: 0;
      & + & {
        margin-top: 28px;
      }

      &--active {
        color: $red;
        cursor: default;
        text-decoration: underline;

        &:hover {
          color: $red;
        }
      }

      &--submenu {
        &,
        &:hover {
          color: #595757;
          cursor: default;
        }
      }
    }

    &__item &__submenu {
      position: static;
      display: flex;
      align-items: flex-start;
      border: none;

      margin-top: 0;

      &-item {
        font-weight: 400;
        font-size: 22px;
        line-height: 138.19%;
        color: #000000;

        padding: 8px 10px;

        border: none;

        &--active {
          color: $red;
          cursor: default;

          &:hover {
            color: $red;
          }
        }
      }
    }
  }
}
</style>
