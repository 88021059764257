<template>
  <div
    v-if="isShowCookieManager"
    id="cookie-manager"
    class="the-cookie-manager"
  >
    <div class="the-cookie-manager__container">
      <div class="the-cookie-manager__title">{{ $t('cookie-manager.title') }}</div>

      <div class="the-cookie-manager__text">{{ $t('cookie-manager.text') }}</div>

      <div v-if="isShowSettings" class="the-cookie-manager__settings">
        <div class="the-cookie-manager__settings-close" @click="hideSettings"></div>

        <div
          class="qv-check the-cookie-manager__settings-item"
          v-for="option in settings"
          :key="option.id"
        >
          <input
            v-model="option.checked"
            type="checkbox"
            :disabled="!option.editable"
          />
          <label>
            <div class="the-cookie-manager__settings-item-title">
              {{ option.title }}
            </div>
            <div class="the-cookie-manager__settings-item-description">
              {{ option.description }}
            </div>
          </label>
        </div>
      </div>

      <div class="the-cookie-manager__buttons">
        <button
          v-if="!isShowSettings"
          class="btn btn--secondary"
          @click="showSettings"
        >{{ $t('cookie-manager.button.settings') }}</button>

        <button
          v-else
          class="btn btn--secondary"
          @click="accept"
        >{{ $t('cookie-manager.button.save') }}</button>

        <button
          class="btn"
          @click="accept"
        >{{ $t('cookie-manager.button.accept') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
const COOKIE_NAME = 'cookiePermission';
const IS_HTTPS = window.location.protocol === 'https';

export default {
  data() {
    return {
      isShowCookieManager: false,
      isShowSettings: false,
      settings: [
        {
          id: 'technische-cookies',
          checked: true,
          title: this.$t('cookie-manager.settings.technische-cookies.title'),
          description: this.$t('cookie-manager.settings.technische-cookies.description'),
          editable: false,
          scripts: [],
          cookie: null,
        },
        {
          id: 'tracking-cookies',
          checked: true,
          title: this.$t('cookie-manager.settings.tracking-cookies.title'),
          description: this.$t('cookie-manager.settings.tracking-cookies.description'),
          editable: true,
          scripts: [
            {
              src: '/js/cookieTagManager.js',
              id: '',
              integrity: '',
              // crossOrigin: 'anonymous',
              crossOrigin: '',
              parent: 'head',
              async: false,
            },
          ],
          cookie: {
            name: 'gtagmanager',
            duration: '365',
            value: 'accept',
            path: '/',
          },
        },
      ],
    };
  },
  computed: {
  },
  created() {
    if (this.isCookieSettingsAlreadySet()) {
      this.addScriptsByCookiesSet();
    } else {
      this.showCookieManager();
    }
  },
  methods: {
    showCookieManager() {
      this.isShowCookieManager = true;
      this.disableSiteInteraction();
    },
    hideCookieManager() {
      this.isShowCookieManager = false;
      this.enableSiteInteraction();
    },
    showSettings() {
      this.isShowSettings = true;
    },
    hideSettings() {
      this.isShowSettings = false;
    },
    disableSiteInteraction() {
      document.body.style.overflow = 'hidden';
    },
    enableSiteInteraction() {
      document.body.style.overflow = 'auto';
    },
    accept() {
      this.setCookie(COOKIE_NAME, 'true', '/', 365);
      this.saveCookieSettings();
      this.hideCookieManager();
    },
    isCookieSettingsAlreadySet() {
      return this.getCookie(COOKIE_NAME) !== null;
    },
    getCookie(name) {
      const dc = document.cookie;
      const prefix = `${name}=`;
      let begin = dc.indexOf(`; ${prefix}`);
      let end = 0;
      if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0) return null;
      } else {
        begin += 2;
        end = document.cookie.indexOf(';', begin);
        if (end === -1) {
          end = dc.length;
        }
      }
      return decodeURI(dc.substring(begin + prefix.length, end));
    },
    setCookie(cookieName, cookieValue, cookiePath, days) {
      const d = new Date();
      d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));

      const params = [
        `${cookieName}=${cookieValue}`,
        `expires=${d.toUTCString()}`,
        `path=${cookiePath}`,
      ];
      if (IS_HTTPS) params.push('secure');

      document.cookie = params.join(';');
    },
    saveCookieSettings() {
      this.settings.forEach(({ checked, editable, cookie }) => {
        // wenn cookie gebraucht wird oder user den cookie wünscht
        if ((!editable || checked) && cookie) {
          this.setCookie(cookie.name, cookie.value, cookie.path, cookie.duration);
        }
      });
      this.addScriptsByCookiesSet();
    },
    addScriptsByCookiesSet() {
      this.settings.forEach(({ cookie, scripts }) => {
        if (cookie && this.getCookie(cookie?.name) !== null) {
          scripts.forEach((script) => this.addScript(script));
        }
      });
    },
    addScript({
      id,
      src,
      // async,
      // integrity,
      // crossorigin,
      parent,
    }) {
      const scriptElement = document.createElement('script');
      const parentElement = document.querySelector(parent) || document.head;

      scriptElement.setAttribute('id', id);
      scriptElement.setAttribute('type', 'text/javascript');
      scriptElement.setAttribute('src', src);
      // scriptElement.setAttribute('async', async);
      // scriptElement.setAttribute('integrity', integrity);
      // scriptElement.setAttribute('crossorigin', crossOrigin);

      parentElement.prepend(scriptElement);
    },
  },
};
</script>

<style lang="scss">
.the-cookie-manager {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 200;

  background: rgba(25, 25, 25, 0.5);

  &__container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 48px;
    @include media-bp-up(tab) {
      padding-left: 150px;
    }

    @include media-bp(mob) {
      padding: 16px;
    }

    color: #343a40;

    background: $white;

    white-space: pre-line;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__settings {
    position: relative;
    padding: 32px 32px 16px 24px;

    @include media-bp(mob) {
      padding-left: 12px;
      padding-bottom: 8px;
    }

    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 12px;
    background: rgba(0,0,0,0.05);

    width: auto;
    max-width: 100%;

    &-close {
      @include close-icon;

      position: absolute;
      right: 8px;
      top: 8px;
    }

    &-item {
      margin-bottom: 8px;
      align-items: flex-start;

      &-title {
        font-size: 16px;
        font-weight: 500;
      }

      &-description {
        font-size: 14px;
      }
    }
  }

  &__buttons {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    .btn {
      margin: 4px;
    }
  }
}
</style>
